html {
  box-sizing: border-box;
  font-size: 62.5%; /* 1rem = 10px */
  background: radial-gradient(circle, rgba(30,30,30,1) 0%, rgba(0,0,0,1) 38%, rgba(22,22,21,1) 71%, rgba(28,28,27,1) 100%);
  
}

*, *:before, *:after {
  box-sizing: inherit;
}

body {
  font-size: 1.8rem;
  line-height: 1;
  /* background-color: rgb(11, 11, 10); */
 
}
/*
/* Globales */
h1, h2, h3 {
  text-align: center;
}


h1 {
  font-size: 2rem;
  font-family:'Ubuntu', 'Cantarell', 'Fira Sans';
  padding-top: 2rem;
}

h2 {
  font-size: 4rem;
}

h3 {
  font-size: 3rem;
}

p{
  padding: 0 2rem;
  font-size: 2rem;
  color: black;
}

