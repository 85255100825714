
.sideBar {
    
    background: linear-gradient(45deg, rgba(4,4,10,1) 0%, rgba(71,71,77,1) 35%, rgba(66,70,71,1) 100%);
    display: flex;
    flex-direction: column;
    grid-area: sideBar;
    height: 100vh;
    position: fixed;
    right: 2%;
    top: 0;
    width: 25%;
}

.contain {
    /* background-color: yellow; */
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width:100%;
}

.containerImg{
    /* background-color: yellow; */
    width: 100%;
    height: 60%;
}


.logoFriki{
    position: absolute;
    /* background-color: red; */
    top: 22%;
    width: 45%;
    right: 27.5%;
    filter: drop-shadow(-0.1rem 0.2rem 0.5rem rgba(242, 240, 240, 0.797));
    z-index: 2;
}

.containtText{
    display: flex;
    flex-direction: column;
    /* background-color: red; */
    height: 50%;
    width: 100%;
}

.containtText span{
    margin: 0 auto;
    max-width: 80%;
    padding: 3rem 1rem;
    display: block;
    text-align: center;
    font-size: 2.8rem;
    color: white;
}

.containtText span:nth-child(2){
  border-top: 2px solid blanchedalmond;
  padding-top: 2rem;
}

.containtText span:nth-child(2), span:nth-child(3){
    font-size: 3.5rem;
    color: blanchedalmond;
    padding: 0.5rem 0;
}


.containtText span:nth-child(5){
    color: rgb(232, 243, 196);
    margin-top: -3rem;
    padding: 1rem 0
}



.textFrikiImg{
    padding: 0.5rem 0;
    width: 45%; 
    top: 58%; 
    right: 27.5%;
   border-bottom: 2px solid blanchedalmond;
   filter: drop-shadow(-0.1rem 0.2rem 0.5rem rgba(242, 240, 240, 0.797));
}

/*---------------- icono pendiente ---------------- */
.containerIcon{
    display: none;
}

.containerList{
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.containerList li {
    list-style: none;
}

.containerIcon .icon{
   width: 3vw;
    position: relative;
}










/*--------------------------- Media Querie 1440px-------------------------- */


@media (max-width: 1440px){

    .logoFriki{
        top: 22%;
        width: 45%;
        right: 27.5%;
    }


    .containtText span{
        max-width: 80%;
        padding: 2rem 1rem;
        font-size: 2.2rem;
    }

    .containtText span:nth-child(2){
      padding-top: 1rem;
    }

    .containtText span:nth-child(2), span:nth-child(3){
        font-size: 3rem;
        padding: 0.5rem 0;
    }


    .containtText span:nth-child(5){
        margin-top: -3rem;
        padding: 1rem 0
    }



    .textFrikiImg{
        padding: 0.5rem 0;
        width: 45%; 
        top: 58%; 
        right: 27.5%;
    }
}




/*--------------------------- Media Querie 1024px-------------------------- */

@media (max-width: 1024px){
   
     .logoFriki{
        top: 22%;
        width: 45%;
        right: 27.5%;
    }
    
    
    .containtText span{
        max-width: 80%;
        padding: 1.2rem 1rem;
        font-size: 1.5rem;
    }
    
    .containtText span:nth-child(2){
      padding-top: 0.8rem;
    }
    
    .containtText span:nth-child(2), span:nth-child(3){
        font-size: 2.5rem;
        padding: 0.5rem 0;
    }
    
    
    .containtText span:nth-child(5){
        margin-top: -3rem;
        padding: 1.5rem 0
    }
    
    
    
    .textFrikiImg{
        padding: 0.5rem 0;
        width: 49%; 
        top: 58%; 
        right: 27.5%;
    }
}





/*--------------------------- Media Querie 768px-------------------------- */

@media (max-width: 768px){
    
    .sideBar{
        position: static;
        width: 100%;
        height: 150%;
        top: 0;
        right: 0;
        padding: 5rem 0 ;
    }
    


    .logoFriki{
        top: 3%;
        width: 15%;
        right: 42.5%;
    }
    
    
    .containtText span{
        max-width: 45%;
        padding: 1.2rem 1rem;
        font-size: 1.55rem;
    }
    
    .containtText span:nth-child(2){
      padding-top: 0.8rem;
    }
    
    .containtText span:nth-child(2), span:nth-child(3){
        font-size: 2rem;
        padding: 0.5rem 0;
    }
    
    
    .containtText span:nth-child(5){
        margin-top: -3rem;
        padding: 1.5rem 0
    }
    
    
    
    .textFrikiImg{
        padding: 0.5rem 0;
        width: 35%; 
        top: 58%; 
        right: 32.5%;
    }

}




/*--------------------------- Media Querie 450px-------------------------- */

@media (max-width: 470px){
    

    .containtText{
        margin: 4rem 0 ;
        width: 100%;
    }

    
    .sideBar{
        position: static;
        width: 100%;
        height: 100%;
        top: 0;
        right: 0;
        padding: 5rem 0 ;
    }
    


    .logoFriki{
        top: 2%;
        width: 19%;
        right: 40.5%;
    }
    
    
    .containtText span{
        max-width: 55%;
        padding: 1.2rem 1rem;
        font-size: 1.55rem;
    }
    
    .containtText span:nth-child(2){
      padding-top: 0.8rem;
    }
    
    .containtText span:nth-child(2), span:nth-child(3){
        font-size: 1.8rem;
        padding: 0.5rem 0;
    }
    
    
    .containtText span:nth-child(5){
        margin-top: -3rem;
        padding: 1.5rem 0
    }
    
    
    
    .textFrikiImg{
        padding: 0.5rem 0;
        width: 40%; 
        top: 58%; 
        right: 32.5%;
    }




}