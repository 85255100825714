

 .navBar {
  grid-area: navbar;
  width: 100%;
  height: 10vh;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-image: url('../../../public/background-navBar.png');
  background-size: cover;
  background-position: center;
  position: fixed;
  top: 0;
  left: 0; 
  z-index: 1;

}

.iconoNav  {
  padding: 0 7rem;
}

.iconoNav img {
  width: 14rem;
}

.navBar ul {
  display: flex;
  align-items: center;
  padding: 0 9rem;
}

.navBar ul li {
  padding: 0 1rem;
  list-style: none;
}


.navBar ul li a img {
  width: 35px;
}

.navBar ul li:nth-child(4) a img{
  width: 30px;
}

.buttomNav {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  font-size: 1.6rem;
  font-weight: 200;
  font-family: "Rubik Mono One", monospace;
}

.buttomNav:hover {
  font-weight: 900;
}





/* ---------------Media queries------------------ */



@media (max-width: 1440px){
  .navBar ul {
    padding: 0 3.5rem;
  }
}



@media (max-width: 1024px){

  .navBar {
    height: 10vh;  
  }

  .iconoNav img  {
    width: 12.5rem;
    
  }

  .buttomNav {
    font-size: 1.3rem;
  }

  .navBar ul{
    padding: 0 4.5rem;
  }

  .navBar ul li {
    padding: 0 rem;
  }

 
}


@media (max-width: 768px){
  .navBar {
    display: none;
  }

}



