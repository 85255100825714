.container-app {
  margin: 0 auto;
  max-width: 90%;
  box-sizing: border-box ;
}


.grid-app {
  display: grid;
  height: 100vh;
  grid-template-areas: "navbar navbar navbar"
                          "mainContent mainContent sideBar"
                          "mainContent mainContent sideBar"
  ;
  grid-template-columns: 2fr 2fr 1.5fr;
  grid-template-rows: auto;
  gap: 10rem 1rem;
}


@media (max-width: 790px){

  .grid-app {
   grid-template-columns: 1fr;
   grid-template-areas: "sideBar"
                        "mainContent"
   ;
   gap: 0 1rem;
  }

  .container-app {
    max-width: 100%;
  }

}

