
.container{
  grid-area: mainContent;   
    background-image: url('../../../public/backg-1920.png');
    background-size: 100% 100%;
    padding: 5rem;
    align-items: center;
    margin-top: -3rem;
} 

.titleH1 {
  font-size: 8rem;
  font-weight: 800;
}

.titleH1 span{
  display: block;
  font-size: 17rem;
}

.titleH2{
  border-top: 2px solid #262323cc;
  border-bottom: 3px solid #262323cc;
  text-align: center;
  max-width: 85%;
  margin: 0 auto;
  padding: 10px 0;
} 

.titleH3{
  margin: 4rem -1rem 0 0 ;
}

.titleH3a{
  border-top: 2px solid #262323cc;
  padding: 4rem 0 2rem;
}

.containerP{
  display: flex;
  flex-direction: row;
}

.containerP div{
  display: flex;
  flex-direction: column;
  width: 40%;
  text-align: justify;
  flex-grow: 1;
}

.p1::first-letter{
  font-size: 5rem;
  text-transform: capitalize;
}

.p2{
  padding-top: 2.5rem;
}

.p3{
  text-align: justify;
}



.imgStorie1{
  margin: 0 auto;
  width: 75%;
}






@media (max-width: 1440px) {

  .container{
    background-image: url('../../../public/backg-1440.png');
  }

  .titleH1 {
    font-size: 6rem;
  }

  .titleH1 span{
    display: block;
    font-size: 15rem;
  }

  .titleH2{
    font-size: 3.8rem;
  }
  
}



@media (max-width: 1024px){

  .container{
    /* background-image: url('../../../public/Papel-MainContent-1050.webp'); */
    background-image: url('../../../public/backg-1024.png');
    padding: 3.2rem;
  }

  .titleH1 {
    font-size: 5rem;
  }

  .titleH1 span{
    display: block;
    font-size: 11rem;
  }

  .titleH2{
    font-size: 2.4rem;
    max-width: 75%;
  }

}


@media (max-width: 768px){

  .container{
    /* background-image: url('../../../public/Papel-MainContent-768.webp'); */
    background-image: url('../../../public/backg-768.png');
    margin: 0 auto;
    background-size: 100% 100% ;
    /* background-repeat: no-repeat; */
    margin-top: 12.5rem;
    padding-top: 3rem;
    padding-bottom: 8rem;
    max-width: 100%;

  }

  .containerP{
    flex-direction: column;
  }

  .containerP div{
  display: flex;
  flex-direction: column;
  width: 100%;
  /* text-align: justify; */
  /* flex-grow: 1; */
  }z

  .containerP div:nth-child(1){
    flex-direction: column;
    /* background-color: yellow; */
    width: 100%;
  }

  .titleH2{
    font-size: 2rem;
    max-width: 70%;
  }
  
  .titleH1 span{
    font-size: 10rem;
  }
  

}

@media (max-width: 470px){
  

  .container{
    padding-top: 18rem;
    max-width: 100vw;
    background-image: url('../../../public/Papel-MainContent-390.png');
    background-size: cover;
    padding: 1.5rem;
    margin-top: -3rem;
  }

  .titleH1 {
    font-size: 4rem;
  }

  .titleH1 span{
    display: block;
    font-size: 6rem;
  }

  .titleH2{
    font-size: 2rem;
    max-width: 75%;
  }
  


}